import Layout from "../../../../src/layouts/mdx";
import GraphQLExample from "../../../../src/components/GraphQLExample";
import basicExample from "../../../../src/examples/createSignatureOrder/webhook/basic";
import secretExample from "../../../../src/examples/createSignatureOrder/webhook/secret";
import * as React from 'react';
export default {
  Layout,
  GraphQLExample,
  basicExample,
  secretExample,
  React
};