import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../layouts/mdx';
export const _frontmatter = {
  "date": "2025-01-25T00:00:00.000Z",
  "title": "Signatory UI settings and webhook signing"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Signatory UI settings`}</h2>
    <p>{`Historically signature UI settings for users have been configured globally on the signature order.`}</p>
    <p>{`This had some drawbacks, as users might reside in different countries and prefer different languages.`}</p>
    <p>{`It is now possible to also define UI settings on a per signatory basis, `}<a parentName="p" {...{
        "href": "/signatures/graphql/examples/#signatory-ui-settings"
      }}>{`See example`}</a></p>
    <h2>{`Webhook signing`}</h2>
    <p>{`Until now, there was no way to validate the authenticity of a signature webhook at the time of the request.`}</p>
    <p>{`We relied on the fact that webhooks contained no actual data, but only identifiers, allowing clients to query our API based on the data in the webhook.
This ensured that only authenticated clients could access signature data.`}</p>
    <p>{`However, it was brought to our attention that an attacker could use the webhook to increase the number of requests a well-behaving client would have to make to our API,
potentially triggering rate limits.`}</p>
    <p>{`To address this, we introduced the option to configure a webhook secret, which adds an HMAC-SHA256 signature to each signature webhook invocation.`}</p>
    <p>{`You can read more about `}<a parentName="p" {...{
        "href": "/signatures/webhooks/configuration/"
      }}>{`configuring and validating webhook secrets`}</a>{`
and also try it out in our `}<a parentName="p" {...{
        "href": "/signatures/webhooks/tester/"
      }}>{`webhook tester`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      