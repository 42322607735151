import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import CodeFlowSnippet from '../../../snippets/oauth2-code-flow.mdx';
import LoginMethodsSnippet from '../../../snippets/login-methods-and-path-encoded.mdx';
import TestUsersSnippet from '../../../snippets/test-users.mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 3,
  "title": "Auth0",
  "subtitle": "This tutorial demonstrates how to integrate Criipto Verify with Auth0."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This tutorial demonstrates how to integrate Criipto Verify with Auth0. The following steps are required to complete your first login:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#register-your-auth0-tenant-in-criipto-verify"
        }}>{`Register your Auth0 tenant in Criipto Verify`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#configure-the-oauth2-code-flow"
        }}>{`Configure your OAuth2 flow`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#create-auth0-connections"
        }}>{`Create Auth0 connections`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#integrate-your-application-with-auth0"
        }}>{`Integrate your application with Auth0`}</a></li>
    </ol>
    <p>{`In the following you will be configuring first Criipto Verify, then Auth0, and finally finishing the Criipto Verify configuration
with the information you get from Auth0. Once configured you may test that everything works from Auth0.`}</p>
    <h2>{`Register your Auth0 tenant in Criipto Verify`}</h2>
    <p>{`First, you must register your Auth0 tenant as an application in Criipto Verify.`}</p>
    <p>{`Once you register your Auth0 tenant, you will also need some of the information for configuring Auth0 to communicate with Criipto Verify. You get these details from the settings of the application in the dashboard.`}</p>
    <p>{`Specifically you need the following information to integrate with Auth0:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Client ID`}</em>{` to identify your Auth0 tenant to Criipto Verify. In the case below we chose `}<inlineCode parentName="li">{`urn:criipto:samples:no1`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Domain`}</em>{` on which you will be communicating with Criipto Verify. Could be for example `}<inlineCode parentName="li">{`samples.criipto.id`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Client secret`}</em>{` is needed if you choose the `}<em parentName="li">{`Back Channel`}</em>{` approach - which we do recommend.
The secret is generated and copied as described further down.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.3125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACEUlEQVQ4y5WRS2/TQBSF56dQYAVLIKzKDtGwyQ6pq5ZWLAqpsqKhsKI/r22akpfzcOLEY3vGTvx2XrA4aCZ2KqEmFYtPZzT3dWYueb5bwIu3H5Db28ebwgHy+yd4tlvAzqs9PH39Ho9zeTx6+Q47KU9y+a2Qo+I5Pn75BqGfSj9wfPpdng9Pyjg4OcNx8Ryl8gVKZxc4/foTh5/L6/wjoRnFFaRd/YX6VQWNqxu0KlXULq+hpHe1ywp6tTrmjo2ZzRFZJprXVSg3t+jc1u6FJIwhYRamnElEoTynGjMLsXUXzxB1sWWmrOIJZyDiYjwcyqZZ82yASHQ0Dc5gIN1lRdmgLOaORutBRDgST1pxd147fQCR51OKsaZJY8TXdXBVha324fQH4D0VrKfKhNg0ERnGRmLTQKDrcPp9RAaVd8TuqdBqdbBOF6zdgd5ogTZb4N0uwtEIwXC4ERF3BwPQlgJ3oMHVNJCIc0STCWa+j3kQ4E+SSH7HMZZRtJ04RmAYsDpdUKUNR+2DRIzB5zY8xiUzz8MiCDD3fambEPFlGCKgFMNGE1q9Ab2pgARik4YJ12LwuC2dLsJwVSj0PtKYeJGn6zDbHenQUNorh4Ftw2ccU9fDMp3+EItUQ7EcShELFUsJGcOEcYxNSz59vs3ZP4h/jExTLifUdQlJbBvxZIJoPMbUdf+roUA21HVElMqGfwGGi8+gq2SkkQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Register App",
          "title": "Register App",
          "src": "/static/9b0c07c946d1ade2ab1a607b8443dead/e5715/register-app.png",
          "srcSet": ["/static/9b0c07c946d1ade2ab1a607b8443dead/8514f/register-app.png 192w", "/static/9b0c07c946d1ade2ab1a607b8443dead/804b2/register-app.png 384w", "/static/9b0c07c946d1ade2ab1a607b8443dead/e5715/register-app.png 768w", "/static/9b0c07c946d1ade2ab1a607b8443dead/5205c/register-app.png 833w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`Configure the OAuth2 code flow`}</h2>

    <CodeFlowSnippet mdxType="CodeFlowSnippet" />
    <h2>{`Create Auth0 connections`}</h2>
    <p>{`You can leverage either an `}<a parentName="p" {...{
        "href": "#enterprise-connection"
      }}>{`Enterprise Connection`}</a>{`, or a `}<a parentName="p" {...{
        "href": "#custom-social-connection"
      }}>{`Custom Social Connection`}</a>{` to connect Criipto Verify with Auth0.`}</p>
    <h3>{`Login-method specific endpoints`}</h3>
    <p>{`To integrate Criipto Verify with Auth0, you create an Auth0 OpenID Connect(OIDC) connection to communicate with Criipto Verify. Because Auth0 will not pass the `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` to Criipto Verify, you will have to create a new connection for every eID option that you intend to use. (`}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` is a parameter in the `}<inlineCode parentName="p">{`/authorize`}</inlineCode>{` request to Criipto Verify needed to specify which kind of eID is requested).`}</p>
    <p>{`For those cases, you can leverage our login-method specific metadata endpoints (for `}<a parentName="p" {...{
        "href": "#enterprise-connection"
      }}>{`Enterprise Connections`}</a>{`) and authorize URLs (for `}<a parentName="p" {...{
        "href": "#custom-social-connection"
      }}>{`Custom Social Connections`}</a>{`). Each of these contain an embedded and base64-encoded variant of the "raw" value normally supplied in the `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{`.`}</p>
    <p>{`Syntax:`}</p>
    <ul>
      <li parentName="ul">{`Issuer metadata endpoint: `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/BASE64(acr_values)/.well-known/openid-configuration`}</inlineCode></li>
      <li parentName="ul">{`Authorize URL: `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/BASE64(acr_values)/oauth2/authorize`}</inlineCode></li>
    </ul>
    <p>{`For example, the `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{` of Norwegian BankID login method is `}<inlineCode parentName="p">{`urn:grn:authn:no:bankid`}</inlineCode>{`. This translates to `}<inlineCode parentName="p">{`dXJuOmdybjphdXRobjpubzpiYW5raWQ=`}</inlineCode>{` in base64 (UTF-8 charset), so the syntax will be:`}</p>
    <ul>
      <li parentName="ul">{`Issuer metadata endpoint: `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/dXJuOmdybjphdXRobjpubzpiYW5raWQ=/.well-known/openid-configuration`}</inlineCode></li>
      <li parentName="ul">{`Authorize URL: `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/dXJuOmdybjphdXRobjpubzpiYW5raWQ=/oauth2/authorize`}</inlineCode></li>
    </ul>
    <h4>{`Choose the specific login method`}</h4>
    <p>{`Below is a list of supported login methods with the corresponding base64 encoded `}<inlineCode parentName="p">{`acr_values`}</inlineCode>{`. Choose the ones you intend to use.`}</p>

    <LoginMethodsSnippet mdxType="LoginMethodsSnippet" />
    <hr />
    <h3>{`Enterprise Connection`}</h3>
    <p>{`You can connect Auth0 to Criipto Verify by creating an OIDC Enterprise Connection. You create an OIDC connection for `}<a parentName="p" {...{
        "href": "#login-method-specific-endpoints"
      }}>{`every login method`}</a>{` you intend to use.`}</p>
    <h4>{`Create the OIDC connection(s)`}</h4>
    <ol>
      <li parentName="ol">{`Go to Auth0 dashboard for your tenant and under `}<strong parentName="li">{`Authentication`}</strong>{` choose `}<strong parentName="li">{`Enterprise`}</strong>{`.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`OpenID Connect`}</strong>{` and create a new connection.`}</li>
      <li parentName="ol">{`Enter `}<strong parentName="li">{`Connection name`}</strong>{`.`}</li>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Issuer URL`}</strong>{` enter the login-method specific URL, as described `}<a parentName="li" {...{
          "href": "#login-method-specific-endpoints"
        }}>{`above`}</a>{`.`}</li>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Client ID`}</strong>{` enter the `}<strong parentName="li">{`Client ID/Realm`}</strong>{` from your Criipto Verify application.`}</li>
      <li parentName="ol">{`Copy the `}<strong parentName="li">{`Callback URL`}</strong>{` generated by Auth0 and enter it under `}<strong parentName="li">{`Callback URLs`}</strong>{` in Criipto Verify application management.`}</li>
      <li parentName="ol">{`Back in the Auth0 dashboard, create a connection by clicking `}<strong parentName="li">{`Create`}</strong>{`.`}</li>
      <li parentName="ol">{`After the connection is created, go to the `}<strong parentName="li">{`Settings`}</strong>{` tab. `}</li>
      <li parentName="ol">{`Choose `}<em parentName="li">{`Front Channel`}</em>{` or `}<em parentName="li">{`Back Channel`}</em>{` as a `}<strong parentName="li">{`Type`}</strong>{`, depending on how you intend to integrate it with your application.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`you should choose `}<em parentName="li">{`Front Channel`}</em>{` only if you are developing a pure SPA application. Otherwise, choose `}<em parentName="li">{`Back Channel`}</em>{`.`}</li>
    </ul>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`If you chose `}<em parentName="li">{`Back Channel`}</em>{` as a `}<strong parentName="li">{`Type`}</strong>{`, enter the `}<strong parentName="li">{`Client Secret`}</strong>{` generated by Criipto Verify when you enabled the OAuth2 Code Flow.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Save Changes`}</strong>{`.`}</li>
      <li parentName="ol">{`Make sure to enable the created connection for your Auth0 application. `}</li>
    </ol>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`  After you save a connection, you may get an error: "Error! Something happened while trying to save your connection: Issuer metadata missing the following attributes: token_endpoint".`}</p>
      <p>{`  This is due to a bug in Auth0's frontend, nothing to worry about. In this case, under the `}<strong parentName="p">{`Issuer URL`}</strong>{` select `}<strong parentName="p">{`Show Issuer Details`}</strong>{` and under `}<strong parentName="p">{`Token Endpoint`}</strong>{` enter `}<inlineCode parentName="p">{`https://<YOUR COMPANY>.criipto.id/oauth2/token`}</inlineCode></p>
    </Highlight>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`  If you are creating multiple connections, you have to enter `}<strong parentName="p">{`Callback URL`}</strong>{` only once. It will be the same for every OpenID Connect connection.`}</p>
    </Highlight>
    <h4>{`Test the connection`}</h4>
    <p>{`To test your OpenID Connect connection, go back to the list of all OpenID Connect connections and select `}<strong parentName="p">{`Try`}</strong>{` button on the right side of the connection you want to test. You will need a `}<a parentName="p" {...{
        "href": "#test-users"
      }}>{`test user`}</a>{` to perform testing.`}</p>
    <h3>{`Custom Social Connection`}</h3>
    <p>{`Alternatively, you can leverage a `}<a href="https://auth0.com/docs/authenticate/identity-providers/social-identity-providers/oauth2" target="_blank">{`Custom Social Connection`}</a>{` to integrate with Criipto Verify via OpenID Connect. Create an OIDC connection for `}<a parentName="p" {...{
        "href": "#login-method-specific-endpoints"
      }}>{`every login method`}</a>{` you intend to use.`}</p>
    <h4>{`Create the OIDC connection(s)`}</h4>
    <ol>
      <li parentName="ol">{`Go to Auth0 dashboard for your tenant and under `}<strong parentName="li">{`Authentication`}</strong>{` choose `}<strong parentName="li">{`Social`}</strong>{`.`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Create Connection`}</strong>{`, go to the bottom of the list, then choose `}<strong parentName="li">{`Create Custom`}</strong>{`.`}</li>
      <li parentName="ol">{`Enter `}<strong parentName="li">{`Connection name`}</strong>{`.`}</li>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Authorization URL`}</strong>{` enter a `}<a parentName="li" {...{
          "href": "#login-method-specific-endpoints"
        }}>{`login-method specific`}</a>{` `}<a parentName="li" {...{
          "href": "https://docs.criipto.com/verify/guides/authorize-url-builder/"
        }}>{`authorize URL`}</a>{` to which your users will be redirected to log in to your application, e.g. `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/dXJuOmdybjphdXRobjpubzpiYW5raWQ=/oauth2/authorize?scope=openid&client_id=urn:criipto:samples:no1&redirect_uri=https://jwt.io&response_type=code`}</inlineCode></li>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Token URL`}</strong>{`, enter your token endpoint: `}<inlineCode parentName="li">{`https://yourdomain.criipto.id/oauth2/token`}</inlineCode></li>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Client ID`}</strong>{` enter the `}<strong parentName="li">{`Client ID/Realm`}</strong>{` from your Criipto Verify application.`}</li>
      <li parentName="ol">{`Under `}<strong parentName="li">{`Client Secret`}</strong>{` enter the `}<strong parentName="li">{`Client Secret`}</strong>{` generated by Criipto Verify when you enabled the `}<a parentName="li" {...{
          "href": "##configure%E2%80%93the-oauth2-code-flow"
        }}>{`OAuth2 Code Flow`}</a>{`.`}</li>
      <li parentName="ol">{`Add the required `}<a href="https://auth0.com/docs/authenticate/identity-providers/social-identity-providers/oauth2#fetch-user-profile-script" target="_blank">{`Fetch User Profile Script`}</a>{` using your `}<inlineCode parentName="li">{`userInfo`}</inlineCode>{` endpoint, e.g.:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function fetchUserProfile(accessToken, context, callback) {
  request.get(
    {
      url: 'https://yourdomain.criipto.id/oauth2/userinfo',
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    },
    (err, resp, body) => {
      if (err) {
        return callback(err);
      }
      if (resp.statusCode !== 200) {
        return callback(new Error(body));
      }
      let bodyParsed;
      try {
        bodyParsed = JSON.parse(body);
      } catch (jsonError) {
        return callback(new Error(body));
      }
      const profile = {
        user_id: bodyParsed.uuid,
        name: bodyParsed.name,
      };
      callback(null, profile);
    }
  );
}
`}</code></pre>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Create a connection by clicking `}<strong parentName="li">{`Create`}</strong>{`.`}</li>
      <li parentName="ol">{`Make sure to enable the created connection for your Auth0 application.`}</li>
      <li parentName="ol">{`Lastly, add Auth0's callback URL (`}<inlineCode parentName="li">{`https://{yourAuth0Domain}/login/callback`}</inlineCode>{`) to your Criipto Verify application. Go to your Criipto Dashboard, and enter the Auth0 Callback URL under `}<strong parentName="li">{`Callback URLs`}</strong>{` in Criipto Verify application management.`}</li>
    </ol>
    <h4>{`Test the connection`}</h4>
    <p>{`Test your Custom Social Connection by clicking the `}<strong parentName="p">{`Try Connection`}</strong>{` button located in the top right corner.`}</p>
    <h3>{`Test users`}</h3>

    <TestUsersSnippet mdxType="TestUsersSnippet" />
    <h2>{`Integrate your application with Auth0`}</h2>
    <p>{`How to integrate your application with Auth0 depends on the technology you are working with. Refer to the `}<a parentName="p" {...{
        "href": "https://auth0.com/docs/quickstarts/"
      }}>{`Auth0 quickstart guide`}</a>{` for more details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      