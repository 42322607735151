import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import GraphQLExample from '../../../components/GraphQLExample';
import basicExample from '../../../examples/createSignatureOrder/webhook/basic';
import secretExample from '../../../examples/createSignatureOrder/webhook/secret';
export const _frontmatter = {
  "product": "signatures",
  "category": "Webhooks",
  "sort": 0,
  "title": "Configuration",
  "subtitle": "Learn how to configure webhooks per signature order"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Webhooks`}</strong>{` are configured on a per-SignatureOrder basis.`}</p>
    <p>{`You simply provide your webhook uri as part of creating a signature order (`}<inlineCode parentName="p">{`createSignatureOrder`}</inlineCode>{` mutation) by adding it as an input variable `}<inlineCode parentName="p">{`webhook.uri`}</inlineCode>{` as shown below.`}</p>
    <h2>{`Adding a webhook`}</h2>

    <GraphQLExample example={basicExample} mdxType="GraphQLExample" />
    <h2>{`Webhook verification with HMAC signature`}</h2>

    <p>{`You can add a cryptographically random secret to your webhook configuration, which will add a
`}<inlineCode parentName="p">{`X-Criipto-Signature`}</inlineCode>{` header containing a HMAC-SHA256 signature (as a base64 string)
of the webhook request body (utf-8).`}</p>
    <p>{`You can also try it out in our `}<a parentName="p" {...{
        "href": "/signatures/webhooks/tester/"
      }}>{`Webhook tester`}</a>{`.`}</p>
    <GraphQLExample example={secretExample} mdxType="GraphQLExample" />
    <h3>{`Node.js example`}</h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`crypto`}</inlineCode>{` library in Node.js to validate the webhook signature.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import crypto from 'crypto';

const secret = Buffer.from('...BASE64_SECRET...', 'base64');
const actual = headers.get('X-Criipto-Signature');
const expected = 
  crypto.createHmac('sha256', secret)
    .update('...RAW BODY...')
    .digest('base64');

console.log(actual === expected);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      